/* eslint-disable no-unused-vars */
import * as Yup from 'yup';
import { Helmet } from 'react-helmet-async';
// @mui
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Swal from 'sweetalert2';
import { OutlinedInput, Stack, Box, Container, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { styled } from '@mui/material/styles';
import { instance } from '../config/Http';
import image from '../components/logo/logo.png';


// components

// sections


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme, openPassword }) => ({
    position: 'relative', // Make this container a positioned parent
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      backgroundImage: "url('./assets/images/olebrassrail_forget.webp')",
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      '&::before': {
        
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#606060',
        opacity:"0.4",
        zIndex: 1, 
      },
    },
  }));
  
  const StyledContent = styled('div')(({ theme }) => ({
    position: 'relative', // Ensure content is above the overlay
    zIndex: 2, // Higher z-index to bring it above the overlay
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1, 5),
    color: 'white', // Ensure text is visible on the dark background
  }));
// ----------------------------------------------------------------------

export default function OTP() {
    const navigate = useNavigate();

    const { enqueueSnackbar } = useSnackbar();

    const VerifyCodeSchema = Yup.object().shape({
        code1: Yup.string().required('Code is required'),
        code2: Yup.string().required('Code is required'),
        code3: Yup.string().required('Code is required'),
        code4: Yup.string().required('Code is required'),

    });

    const defaultValues = {
        code1: '',
        code2: '',
        code3: '',
        code4: '',

    };

    const {
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting, isValid },
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(VerifyCodeSchema),
        defaultValues,
    });

    const values = watch();

    useEffect(() => {
        document.addEventListener('paste', handlePasteClipboard);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (data) => {
        try {
            const token = `${data.code1}${data.code2}${data.code3}${data.code4}`;


            const login = new FormData();
            login.append('token', token); // Send the token as a concatenated number

            // API request
            const response = await instance.post(`admin/verify`, login);

            console.log(response, 'working');
            const status = response?.data?.status;
            console.log(response?.data?.token, '========================');

            if (status === true) {
                Swal.fire({
                    title: 'Good job!',
                    text: 'Verify Successfully!',
                    icon: 'success',
                    button: 'Ok',
                });
                navigate("/update_password")
            } else {
                console.error('Verification failed');
                navigate("/login")
            }
        } catch (error) {
            console.error(error?.message);
            Swal.fire({
                title: 'Something Went Wrong',
                text: error?.message,
                icon: 'error',
                dangerMode: true,
            });
        }
    };


    const handlePasteClipboard = (event) => {
        let data = event?.clipboardData?.getData('Text') || '';

        data = data.split('');

        [].forEach.call(document.querySelectorAll('#field-code'), (node, index) => {
            node.value = data[index];
            const fieldIndex = `code${index + 1}`;
            setValue(fieldIndex, data[index]);
        });
    };

    const handleChangeWithNextField = (event, handleChange) => {
        const { maxLength, value, name } = event.target;
        const fieldIndex = name.replace('code', '');

        const fieldIntIndex = Number(fieldIndex);

        if (value.length >= maxLength) {
            if (fieldIntIndex < 4) {
                const nextfield = document.querySelector(`input[name=code${fieldIntIndex + 1}]`);

                if (nextfield !== null) {
                    nextfield.focus();
                }
            }
        }

        handleChange(event);
    };
    // const mdUp = useResponsive('up', 'md');

    return (
        <>


            <StyledRoot >

                <Container maxWidth="sm">
                    <StyledContent>
                        <Box sx={{ height: 'auto', width: "100%" }}>
                            <img src={image} alt="" style={{
                                margin: "auto",
                                height: 'auto',
                                width: "100%",
                                objectFit: "cover"
                            }} />
                        </Box>


                        <Typography variant="h3" sx={{ mt: 5, mb: 5, mx: 'auto', color: 'black' }}>
                            Welcome To Admin Login
                        </Typography>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <Stack direction="row" spacing={2} justifyContent="center">
                                {Object.keys(values).map((name, index) => (
                                    <Controller
                                        key={name}
                                        name={`code${index + 1}`}
                                        control={control}
                                        render={({ field }) => (
                                            <OutlinedInput
                                                {...field}
                                                id="field-code"
                                                autoFocus={index === 0}
                                                placeholder="-"
                                                onChange={(event) => handleChangeWithNextField(event, field.onChange)}
                                                inputProps={{
                                                    maxLength: 1,
                                                    sx: {
                                                        p: 0,
                                                        textAlign: 'center',
                                                        color: 'white', // Text color
                                                        border: '1px solid #fff',
                                                        width: { xs: 36, sm: 56 },
                                                        height: { xs: 36, sm: 56 },
                                                    },
                                                }}
                                                sx={{
                                                    '& .MuiInputBase-input': {
                                                        textAlign: 'center',
                                                    },
                                                    '& .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white', // Default border color
                                                    },
                                                    '&:hover .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white', // Hover border color
                                                    },
                                                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                                        borderColor: 'white', // Focus border color
                                                    },
                                                    input: {
                                                        color: 'white', // Input text color
                                                    },
                                                }}
                                            />
                                        )}
                                    />
                                ))}
                            </Stack>

                            <LoadingButton
                                fullWidth
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                                disabled={!isValid}
                                sx={{
                                    marginTop: 3,
                                    color: 'white',
                                    backgroundColor: '#D32D0B', // Default background color
                                    '&:hover': {
                                        backgroundColor: '#D32D0B', // Same color on hover
                                    },
                                }}
                            >
                                Verify
                            </LoadingButton>
                        </form>

                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
