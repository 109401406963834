/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
// @mui
import { useState } from 'react';
import { Stack, Container, IconButton, TextField, ThemeProvider, InputAdornment, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';
// hooks
import { LoadingButton } from '@mui/lab';
import { instance } from '../config/Http';
import Iconify from '../components/iconify';
// import useResponsive from '../hooks/useResponsive';
import image from '../components/logo/logo.png';
// components

// sections



// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme, openPassword }) => ({
    position: 'relative', // Make this container a positioned parent
    [theme.breakpoints.up('md')]: {
        display: 'flex',
        backgroundImage: "url('./assets/images/olebrassrail_forget.webp')",
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        '&::before': {

            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#606060',
            opacity: "0.4",
            zIndex: 1,
        },
    },
}));

const StyledContent = styled('div')(({ theme }) => ({
    position: 'relative', // Ensure content is above the overlay
    zIndex: 2, // Higher z-index to bring it above the overlay
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(1, 5),
    color: 'white', // Ensure text is visible on the dark background
}));

// ----------------------------------------------------------------------

export default function ConfirmPassword() {
    // const mdUp = useResponsive('up', 'md');

    const [loader, setLoader] = useState(false);
    const [emailConfirmation, setEmailConfirmation] = useState('');
    const [password, setPassword] = useState('');
    const tokenConfirm = localStorage.getItem('confirm_token')
    console.log(tokenConfirm,"tokenConfirm");
    
    // const currentprotocol = window.location.protocol;

    const Navigate = useNavigate();
    const landlogin = () => {
        setLoader(true);

        try {
            const login = new FormData();
            login.append('password', password);
            login.append('password_confirmation', emailConfirmation);
            login.append('token', tokenConfirm);


            instance.post(`admin/reset/password`, login).then((response) => {
                console.log(response, 'working');
                //  setToken(response?.data?.accessToken)
                const status = response?.data?.status;
                console.log(response?.data?.token, '========================');

                setLoader(false);

                if (status === true) {
                    Swal.fire({
                        title: 'Good job!',
                        text: "Password Change Successfully!",
                        icon: 'success',
                        button: 'Ok',
                    });
                    Navigate('/login');
                    setLoader(false);
                } else {
                    setLoader(false);
                }

            });
        } catch (error) {
            setLoader(false);
            console.log(error?.message);
            Swal.fire({
                title: 'Something Went Wrong',
                text: error?.message,
                icon: 'error',
                dangerMode: true,
            });

        }


    };

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordTwo, setShowPasswordTwo] = useState(false);


    return (
        <>
            <Helmet>
                <title> Login | Ole brass rail </title>
            </Helmet>

            <StyledRoot >

                <Container maxWidth="sm">
                    <StyledContent>
                        <Box sx={{ height: 'auto', width: "100%" }}>
                            <img src={image} alt="" style={{
                                margin: "auto",
                                height: 'auto',
                                width: "100%",
                                objectFit: "cover"
                            }} />
                        </Box>
                        <Stack spacing={3}>

                            <ThemeProvider >
                                <TextField
                                    name="password"
                                    label="Password"
                                    color="success"
                                    onChange={(e) => setPassword(e.target.value)}
                                    variant="outlined"
                                    type={showPassword ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                                    <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' }, // Change label color to white by default
                                    }}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            color: 'white', // Default label color
                                            '&.Mui-focused': {
                                                color: 'white', // Label color on focus
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            color: 'white', // Default input text color
                                            '&:hover': {
                                                color: 'white', // Input text color on hover
                                            },
                                            '&.Mui-focused': {
                                                color: 'white', // Input text color on focus
                                            },
                                        },
                                        '& .MuiIconButton-root': {
                                            color: 'white', // Change icon button color to white
                                        },
                                    }}
                                />
                            </ThemeProvider>
                            <ThemeProvider >
                                <TextField
                                    name="password_confirmation"
                                    label="Password Confirmation"
                                    color="success"
                                    onChange={(e) => setEmailConfirmation(e.target.value)}
                                    variant="outlined"
                                    type={showPasswordTwo ? 'text' : 'password'}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() => setShowPasswordTwo(!showPasswordTwo)} edge="end">
                                                    <Iconify icon={showPasswordTwo ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        style: { color: 'white' },
                                    }}
                                    InputLabelProps={{
                                        style: { color: 'white' }, // Change label color to white by default
                                    }}
                                    sx={{
                                        '& .MuiInputLabel-root': {
                                            color: 'white', // Default label color
                                            borderColor: 'white',
                                            '&.Mui-focused': {
                                                color: 'white',
                                                borderColor: 'white', // Label color on focus
                                            },
                                        },
                                        '& .MuiInputBase-root': {
                                            color: 'white',
                                            borderColor: 'white', // Default input text color
                                            '&:hover': {
                                                color: 'white',
                                                borderColor: 'white', // Input text color on hover
                                            },
                                            '&.Mui-focused': {
                                                color: 'white',
                                                borderColor: 'white', // Input text color on focus
                                            },
                                        },
                                        '& .MuiIconButton-root': {
                                            color: 'white',
                                            borderColor: 'white',// Change icon button color to white
                                        },
                                    }}
                                />
                            </ThemeProvider>
                        </Stack>

                        <LoadingButton
                            loading={loader}
                            loadingPosition="start"
                            fullWidth
                            size="large"
                            type="submit"
                            variant="contained"
                            color="success"
                            sx={{
                                marginTop: 3,
                                color: 'white',
                                '&.MuiLoadingButton-loading': {
                                    backgroundColor: '#D32D0B', // Green color for success
                                    color: 'white',
                                },
                            }}
                            onClick={landlogin}
                        >
                            Forget Password
                        </LoadingButton>

                    </StyledContent>
                </Container>
            </StyledRoot>
        </>
    );
}
