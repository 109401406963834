/* eslint-disable react/self-closing-comp */
// component
import SvgColor from '../../../components/svg-color';
// ----------------------------------------------------------------------
const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Dashboard',
    path: '/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: 'Gallery',
    path: '/dashboard/gallery',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeWidth={1.5}><circle cx={16} cy={8} r={2}></circle><path strokeLinecap="round" d="m2 12.5l1.752-1.533a2.3 2.3 0 0 1 3.14.105l4.29 4.29a2 2 0 0 0 2.564.222l.299-.21a3 3 0 0 1 3.731.225L21 18.5"></path><path strokeLinecap="round" d="M22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2s7.071 0 8.535 1.464c.974.974 1.3 2.343 1.41 4.536"></path></g></svg>,
  },
  {
    title: 'Upcoming Events',
    path: '/dashboard/upcoming_events',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M15 22v-2h4V10H5v4H3V6q0-.825.588-1.412T5 4h1V2h2v2h8V2h2v2h1q.825 0 1.413.588T21 6v14q0 .825-.587 1.413T19 22zm-7 2l-1.4-1.4L9.175 20H1v-2h8.175L6.6 15.4L8 14l5 5zM5 8h14V6H5zm0 0V6z"></path></svg>,
  },
  {
    title: 'Breakfast Menu',
    path: '/dashboard/breakfast_menu',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 2048 2048"><path fill="currentColor" d="M1408 592q-26 0-45-19t-19-45q0-51 19-98t56-83l79-80q38-38 38-91q0-26 19-45t45-19t45 19t19 45q0 51-19 98t-56 83l-79 80q-38 38-38 91q0 26-19 45t-45 19m-384 0q-26 0-45-19t-19-45q0-51 19-98t56-83l79-80q38-38 38-91q0-26 19-45t45-19t45 19t19 45q0 51-19 98t-56 83l-79 80q-38 38-38 91q0 26-19 45t-45 19m832 176q40 0 75 15t61 41t41 61t15 75v384q0 40-15 75t-41 61t-61 41t-75 15h-57q-2 7-3 13t-4 12v39q0 66-25 124t-69 102t-102 69t-124 25h-384q-78 0-144-35t-110-93H334q-66 0-124-25t-102-68t-69-102t-25-125v-64h256q0-79 30-149t83-122t122-83t149-30q30 0 58 5t56 14V640h1024v128zM654 1152q-53 0-99 20t-82 55t-55 81t-20 100h370v-228q-26-13-54-20t-60-8m-320 512h441q-7-29-7-64v-64H153q10 28 28 51t41 41t52 26t60 10m463 67v1l1 2v-1zm867-131V768H896v832q0 40 15 75t41 61t61 41t75 15h384q40 0 75-15t61-41t41-61t15-75m256-256V960q0-26-19-45t-45-19h-64v512h64q26 0 45-19t19-45"></path></svg>,
  }, {
    title: 'Dinner Menu',
    path: '/dashboard/dinner_menu',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 512 512"><path fill="currentColor" d="M464 344.063c0-109.308-84.755-199.193-192-207.39V80h-32v56.673c-107.245 8.2-192 98.082-192 207.39v33.107h416Zm-32 1.107H80v-1.107c0-97.046 78.953-176 176-176s176 78.953 176 176ZM16 416h480v32H16z"></path></svg>,
  }, {
    title: 'Daily Specials',
    path: '/dashboard/daily_specials',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} color="currentColor"><path d="M7.5 3.5c-1.556.047-2.483.22-3.125.862c-.879.88-.879 2.295-.879 5.126v6.506c0 2.832 0 4.247.879 5.127C5.253 22 6.668 22 9.496 22h5c2.829 0 4.243 0 5.121-.88c.88-.879.88-2.294.88-5.126V9.488c0-2.83 0-4.246-.88-5.126c-.641-.642-1.569-.815-3.125-.862"></path><path d="M7.496 3.75c0-.966.784-1.75 1.75-1.75h5.5a1.75 1.75 0 1 1 0 3.5h-5.5a1.75 1.75 0 0 1-1.75-1.75M13.5 11H17M7 12s.5 0 1 1c0 0 1.588-2.5 3-3m2.5 7H17m-9 0h1"></path></g></svg>,
  },
  //  {
  //   title: 'Video',
  //   path: '/dashboard/video',
  //   icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="currentColor" d="M22 2H2v20h20zm-4.25 10L8 17.629V6.37z"></path></svg>,
  // },
   {
    title: 'Update email/Password',
    path: '/dashboard/update',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" color="currentColor"><path d="M15 10.75a3 3 0 1 1-6 0a3 3 0 0 1 6 0"/><path d="m20.068 6.492l-.43-.75c-.325-.566-.488-.85-.765-.963s-.59-.024-1.216.155l-1.064.3c-.4.093-.82.04-1.184-.148l-.294-.17a1.75 1.75 0 0 1-.687-.847l-.291-.872c-.192-.578-.287-.867-.515-1.032S13.092 2 12.486 2h-.972c-.605 0-.908 0-1.136.165s-.323.454-.515 1.032l-.29.872a1.75 1.75 0 0 1-.688.847l-.294.17c-.365.188-.784.24-1.184.148l-1.064-.3c-.626-.179-.94-.268-1.216-.155c-.277.113-.44.397-.765.963l-.43.75c-.305.531-.458.797-.428 1.08s.233.51.642.966l.899 1.009c.22.279.375.765.375 1.203s-.156.924-.375 1.203l-.9 1.009c-.408.456-.612.683-.641.966s.123.549.428 1.08l.43.75c.325.566.488.85.765.963s.59.024 1.216-.155l1.064-.3c.4-.093.82-.04 1.184.148l.294.17c.313.201.554.498.687.847l.291.872c.192.578.287.867.515 1.032s.53.165 1.136.165h.972c.605 0 .908 0 1.136-.165s.323-.454.515-1.032l.291-.872a1.74 1.74 0 0 1 .687-.847l.294-.17a1.73 1.73 0 0 1 1.184-.148l1.064.3c.626.179.94.268 1.216.155c.277-.113.44-.397.765-.963l.43-.75c.305-.531.458-.797.428-1.08s-.233-.51-.642-.966l-.899-1.009c-.22-.28-.375-.765-.375-1.203c0-.437.156-.924.375-1.203l.9-1.009c.408-.456.612-.683.641-.966s-.122-.549-.428-1.08"/><path d="M22 18c0 .93 0 1.395-.102 1.776a3 3 0 0 1-2.122 2.122C19.396 22 18.93 22 18 22H6c-.93 0-1.395 0-1.776-.102a3 3 0 0 1-2.122-2.122C2 19.396 2 18.93 2 18"/></g></svg>,
  }, {
    title: 'Contact',
    path: '/dashboard/contact',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 2048 2048"><path fill="currentColor" d="M1330 1203q136 47 245 131t186 196t118 243t41 275h-128q0-164-58-304t-162-244t-243-161t-305-59q-107 0-206 27t-184 76t-155 119t-119 155t-77 185t-27 206H128q0-144 42-275t119-242t186-194t245-133q-78-42-140-102T475 969t-67-157t-24-172q0-133 50-249t137-204T774 50t250-50q133 0 249 50t204 137t137 203t50 250q0 88-23 171t-67 156t-105 133t-139 103M512 640q0 106 40 199t110 162t163 110t199 41t199-40t162-110t110-163t41-199t-40-199t-110-162t-163-110t-199-41t-199 40t-162 110t-110 163t-41 199"></path></svg>,
  },{
    title: 'Queries',
    path: '/dashboard/queries',
    icon: <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><path fill="none" stroke="currentColor" strokeWidth={2} d="M1 2h21v16h-8l-8 4v-4H1zm5 8h1v1H6zm5 0h1v1h-1zm5 0h1v1h-1z"></path></svg>
  },
];

export default navConfig;
