/* eslint-disable no-unused-vars */
import { Helmet } from 'react-helmet-async';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, Container, Typography } from '@mui/material';
import { useState } from 'react';
// hooks
// import useResponsive from '../hooks/useResponsive';
import image from '../components/logo/logo.png';
// components

// sections
import { LoginForm } from '../sections/auth/login';


// ----------------------------------------------------------------------

const StyledRoot = styled('div')(({ theme, openPassword }) => ({
  position: 'relative', // Make this container a positioned parent
  [theme.breakpoints.up('md')]: {
    display: 'flex',
    backgroundImage: "url('./assets/images/olebrassrail_forget.webp')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    '&::before': {
      
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: '#606060',
      opacity:"0.4",
      zIndex: 1, 
    },
  },
}));

const StyledContent = styled('div')(({ theme }) => ({
  position: 'relative', // Ensure content is above the overlay
  zIndex: 2, // Higher z-index to bring it above the overlay
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(1, 5),
  color: 'white', // Ensure text is visible on the dark background
}));


// ----------------------------------------------------------------------

export default function LoginPage() {
  // const mdUp = useResponsive('up', 'md');
  const [openPassword, setOpenPassword] = useState(false)
  return (
    <>
      <Helmet>
        <title style={{color:"black"}}> Login | Ole brass rail </title>
      </Helmet>

      <StyledRoot openPassword={openPassword}>

        <Container maxWidth="sm">
          <StyledContent>
            <Box sx={{ height: 'auto', width: "100%" }}>
              <img src={image} alt="" style={{
                margin: "auto",
                height: 'auto',
                width: "100%",
                objectFit: "cover"
              }} />
            </Box>
            {!openPassword &&

              <Typography variant="h3" sx={{ mt: 5, mb: 5, mx: 'auto', color: 'black' }}>
                Welcome To Admin Login
              </Typography>
            }
            <LoginForm openPassword={openPassword} setOpenPassword={setOpenPassword} />
          </StyledContent>
        </Container>
      </StyledRoot>
    </>
  );
}
