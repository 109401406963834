/*eslint-disable */
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Stack, IconButton, InputAdornment, TextField, createTheme, ThemeProvider } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Swal from 'sweetalert2';
import { instance } from '../../../config/Http';
import Iconify from '../../../components/iconify';
// import Swal from 'sweetalert2';

// ----------------------------------------------------------------------

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              border: '2px solid white', // Replace 'red' with your desired border color
            },
            '&:hover fieldset': {
              borderColor: '#D32D0B', // Replace 'blue' with your desired hover color
            },
          },
        },
      },
    },
  },
});

export default function LoginForm({openPassword, setOpenPassword}) {
  const [loader, setLoader] = useState(false);
  const [email, setEmail] = useState('');
  const [emailForget, setEmailForget] = useState('');
  const [password, setPassword] = useState('');
  
  // const currentprotocol = window.location.protocol;

  const Navigate = useNavigate();
  const landlogin = () => {
    setLoader(true);
    if (openPassword) {
      try {
        const login = new FormData();
        login.append('email', emailForget);

        instance.post(`admin/sent/email`, login).then((response) => {
          console.log(response, 'working');
          //  setToken(response?.data?.accessToken)
          const status = response?.data?.status;
          console.log(response?.data?.token, '========================');
        
          setLoader(false);

          if (status === true) {
            Swal.fire({
              title: 'Good job!',
              text: 'Email Send SuccessFully!',
              icon: 'success',
              button: 'Ok',
            });
            Navigate('/otp');
            localStorage.setItem('confirm_token', response?.data?.token);
            setOpenPassword(false)
            setLoader(false);
          } else {
            setLoader(false);
            setOpenPassword(false)
          }
        });
      } catch (error) {
        setLoader(false);
        console.log(error?.message);
        Swal.fire({
          title: 'Something Went Wrong',
          text: error?.message,
          icon: 'error',
          dangerMode: true,
        });
        setOpenPassword(false)
      }
    } else {

      try {
        const login = new FormData();
        login.append('email', email);
        login.append('password', password);

        instance.post(`admin/login`, login).then((response) => {
          console.log(response, 'working');
          //  setToken(response?.data?.accessToken)
          const status = response?.data?.status;
          const message = response?.data?.message;
          console.log(response?.data?.token, '========================');
          localStorage.setItem('accessToken', response?.data?.token);
          setLoader(false);

          if (status === true) {
            Swal.fire({
              title: 'Good job!',
              text: 'Admin Login SuccessFully!',
              icon: 'success',
              button: 'Ok',
            });
            Navigate('/dashboard/app');
            setLoader(false);
          } else {
            Swal.fire({
              title: 'oops',
              text: message,
              icon: 'error',
              button: 'Ok',
            });
            setLoader(false);
          }
        });
      } catch (error) {
        setLoader(false);
        console.log(error?.message);
        Swal.fire({
          title: 'Something Went Wrong',
          text: error?.message,
          icon: 'error',
          dangerMode: true,
        });
      }
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  return (
    <>
      {openPassword ?
        <>
          <Stack spacing={3}>
            <ThemeProvider theme={theme}>
              <TextField
                name="email"
                label="Email"
                color="success"
                onChange={(e) => setEmailForget(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'white' },
                }}
                InputProps={{
                  style: { color: 'white' },
                }}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: 'white', // Default label color
                    '&.Mui-focused': {
                      color: 'white', // Label color on focus
                    },
                  },
                  '& .MuiInputBase-root': {
                    color: 'white', // Default input text color
                    '&:hover': {
                      color: 'white', // Input text color on hover
                    },
                    '&.Mui-focused': {
                      color: 'white', // Input text color on focus
                    },
                  },
                }}
              />
            </ThemeProvider>
          </Stack>
        </>
        :
        <>
          <Stack spacing={3}>
            <ThemeProvider theme={theme}>
              <TextField
                name="email"
                label="Email"
                color="success"
                onChange={(e) => setEmail(e.target.value)}
                variant="outlined"
                InputLabelProps={{
                  style: { color: 'white' },
                }}
                InputProps={{
                  style: { color: 'white' },
                }}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: 'white', // Default label color
                    '&.Mui-focused': {
                      color: 'white', // Label color on focus
                    },
                  },
                  '& .MuiInputBase-root': {
                    color: 'white', // Default input text color
                    '&:hover': {
                      color: 'white', // Input text color on hover
                    },
                    '&.Mui-focused': {
                      color: 'white', // Input text color on focus
                    },
                  },
                }}
              />
            </ThemeProvider>
            <ThemeProvider theme={theme}>
              <TextField
                name="password"
                label="Password"
                color="success"
                onChange={(e) => setPassword(e.target.value)}
                variant="outlined"
                type={showPassword ? 'text' : 'password'}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                        <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { color: 'white' },
                }}
                InputLabelProps={{
                  style: { color: 'white' }, // Change label color to white by default
                }}
                sx={{
                  '& .MuiInputLabel-root': {
                    color: 'white', // Default label color
                    '&.Mui-focused': {
                      color: 'white', // Label color on focus
                    },
                  },
                  '& .MuiInputBase-root': {
                    color: 'white', // Default input text color
                    '&:hover': {
                      color: 'white', // Input text color on hover
                    },
                    '&.Mui-focused': {
                      color: 'white', // Input text color on focus
                    },
                  },
                  '& .MuiIconButton-root': {
                    color: 'white', // Change icon button color to white
                  },
                }}
              />
            </ThemeProvider>
          </Stack>
        </>
      }
      <div style={{ display: "flex", justifyContent: "right", cursor: "pointer" }}>

        <button style={{ color: "black", marginTop: "5px", marginBottom: "0px", background: "none", border: "none",fontWeight:"700",fontSize:"15px" }} onClick={() => setOpenPassword(!openPassword)}> {openPassword ? "Cancel" : "Forget Password"}</button>
      </div>
      <LoadingButton
        loading={loader}
        loadingPosition="start"
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        color="success"
        sx={{
          marginTop: 3,
          color: 'white',
          '&.MuiLoadingButton-loading': {
            backgroundColor: '#D32D0B', // Green color for success
            color: 'white',
          },
        }}
        onClick={landlogin}
      >
        {openPassword ? "Forget Password" : "Login"}
      </LoadingButton>
    </>

  )

}
