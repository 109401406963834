/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
/* eslint-disable react/jsx-key */
import { Helmet } from 'react-helmet-async';
import { useEffect, useState, useCallback } from 'react';
import { Card, Stack, Grid, MenuItem, Container, Typography, CardActions, CardMedia, Button } from '@mui/material';
import Swal from 'sweetalert2';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Iconify from '../../components/iconify';
import { instance } from '../../config/Http';
import ViewUpcomingModal from './ViewUpcomingModal';
import ViewUpcomingModalEdit from './ViewUpcomingModalEdit';

export default function ViewUpcoming() {
  const [USERLIST, setUSERLIST] = useState([]);
  const [loader, setLoader] = useState(false);
  const [handleButton, setHandleButton] = useState(false);

  const [USERLISTUpdated, setUSERLISTUpdated] = useState([]); // The new state to hold the array of objects with ids

  useEffect(() => {
    const updatedList = USERLIST.map((e) => ({
      id: e?.id,
    }));
    setUSERLISTUpdated(updatedList);
  }, [USERLIST]);

  const UpdateList = () => {
    setHandleButton(true);
    try {
      const Data = new FormData();
      USERLISTUpdated.forEach((e, index) => {
        Data.append(`images[${index}][id]`, e.id);
        Data.append(`images[${index}][position]`, index + 1);
      });
      instance
        .post(`admin/upcoming/sorting`, Data)
        .then((response) => {
          console.log(response, 'response');

          const status = response?.status;
          if (status === 200) {
            Swal.fire({
              title: 'Good job!',
              text: 'Sorted Successfully',
              icon: 'success',
              button: 'Ok',
            });
            setHandleButton(false);
            user();
          } else {
            Swal.fire({
              title: 'Something Went Wrong',
              text: 'Failed',
              icon: 'error',
              dangerMode: true,
            });
            setHandleButton(false);
          }
        })
        .catch((error) => {
          setHandleButton(false);

          Swal.fire({
            title: 'Something Went Wrong',
            text: error?.message,
            icon: 'error',
            dangerMode: true,
          });
          console.error('Error: ', error);
        });
    } catch (error) {
      Swal.fire({
        title: 'Something Went Wrong',
        text: error?.message,
        icon: 'error',
        dangerMode: true,
      });
    }
  };

  // add
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  // edit
  const [openedit, setOpenedit] = useState(false);
  const [EditData, setEditData] = useState(null);
  const handleCloseedit = () => setOpenedit(false);
  const handleOpenedit = (e) => {
    setEditData(e);
    setOpenedit(true);
  };

  const token = localStorage.getItem('accessToken');

  const user = useCallback(() => {
    setLoader(true);
    instance
      .get('admin/view_upcoming')
      .then((response) => {
        setLoader(false);
        setUSERLIST(response?.data?.data);
        localStorage.setItem('lenderLength', response?.data?.data?.length);
        console.log(response, 'user api');
      })
      .catch((error) => {
        setLoader(false);
        console.log(error);
      });
    console.log(token);
  }, [token]);

  useEffect(() => {
    user();
  }, [user]);

  const delet = (id) => {
    setLoader(true);
    try {
      instance.get(`admin/upcoming/delete/${id}`).then((response) => {
        if (response?.data.status === true) {
          setLoader(false);
          Swal.fire({
            title: 'Good job! ',
            text: 'Upcoming Event deleted successFully',
            icon: 'success',
            button: 'Ok',
          });
          user();
        } else {
          setLoader(false);
        }
      });
    } catch (error) {
      setLoader(false);
      Swal.fire({
        title: 'Some Thing Went Wrong! ',
        text: error?.message,
        icon: 'danger',
        button: 'Ok',
      });
    }
  };

  // Handle drag and drop functionality
  const handleDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return; // If dropped outside

    if (destination.index === source.index) return; // If dropped in the same place

    const updatedList = Array.from(USERLIST);
    const [movedItem] = updatedList.splice(source.index, 1); // Remove the item
    updatedList.splice(destination.index, 0, movedItem); // Add the item to the new position

    setUSERLIST(updatedList); // Update the state
  };

  return (
    <>
      <Helmet>
        <title> Upcoming Event | Ole brass rail </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Upcoming Event
          </Typography>
          <Button
            onClick={handleOpen}
            variant="contained"
            sx={{ backgroundColor: '#D32D0B' }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Image
          </Button>
        </Stack>
        <Button
          disabled={handleButton}
          onClick={UpdateList}
          variant="contained"
          sx={{ backgroundColor: '#D32D0B', margin: '10px ' }}
        >
          Update Sort
        </Button>
        {/* Drag and Drop Container */}
        <DragDropContext onDragEnd={handleDragEnd}>
  <Droppable droppableId="droppable" direction="horizontal">
    {(provided) => (
      <Grid
        container
        spacing={2}
        ref={provided.innerRef}
        {...provided.droppableProps}
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          overflowY: 'hidden', // Enable vertical scrolling
        }}
      >
        {/* Render Draggable Cards */}
        {USERLIST.map((e, i) => (
          <Draggable key={e?.id} draggableId={e?.id.toString()} index={i}>
            {(provided) => (
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                lg={3}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                sx={{
                  display: 'flex',
                  justifyContent: 'center', // Optional: Centers the card in its grid cell
                }}
              >
                <Card sx={{ maxWidth: 400 }}>
                  <CardMedia sx={{ height: 350 }} image={e?.image} title="Upcoming Event" />
                  <CardActions>
                    <Button size="small">{e?.created_at?.slice(0, 10)}</Button>
                    <MenuItem sx={{ color: 'error.main' }} onClick={() => delet(e?.id)}>
                      <Iconify icon={'eva:trash-2-outline'} />
                    </MenuItem>
                    <MenuItem sx={{ color: 'error.main' }} onClick={() => handleOpenedit(e)}>
                      Edit
                    </MenuItem>
                  </CardActions>
                </Card>
              </Grid>
            )}
          </Draggable>
        ))}
        {provided.placeholder} {/* Provides the placeholder when dragging */}
      </Grid>
    )}
  </Droppable>
</DragDropContext>


        {/* Add Modal */}
        <ViewUpcomingModal open={open} setOpen={setOpen} handleClose={handleClose} user={user} />
        {/* Edit Modal */}
        <ViewUpcomingModalEdit
          EditData={EditData}
          openedit={openedit}
          setOpenedit={setOpenedit}
          handleCloseedit={handleCloseedit}
          user={user}
        />
      </Container>
    </>
  );
}
